<template>
  <div class="cart-product-discount__item" v-if="visible">
    <div class="cart-product-discount__label">
      {{ discount.label }}
      <div
        v-if="discount.removable"
        class="cart-product-discount__remove icon-delete"
        @click="removeItem(discount)"
      ></div>
    </div>
    <div class="cart-product-discount__value">
      {{ filterPrice(discount.price.unitPrice) }}
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['removeDiscount']);

const props = defineProps({
  discount: {
    type: Object,
    default: () => {},
  },
});

const visible = ref<boolean>(true);

const removeItem = (discount) => {
  visible.value = false;
  emit('removeDiscount', discount);
};
</script>

<style scoped lang="scss">
.cart-product-discount__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-xs;
  background: $color-yellow;
  padding: 0 $spacing-xs;

  &:first-child {
    padding-top: $spacing-xs;
    border-top-left-radius: $radius-small;
    border-top-right-radius: $radius-small;
  }

  &:last-child {
    padding-bottom: $spacing-xs;
    border-bottom-left-radius: $radius-small;
    border-bottom-right-radius: $radius-small;
  }

  & + & {
    padding-top: 0.5rem;
  }
}

.cart-product-discount__label {
  font-size: $text-sm;
  display: flex;
  align-items: center;
  gap: $spacing-xxs;
}

.cart-product-discount__remove {
  // color: $color-gray;
  font-size: $text-sm;
  cursor: pointer;
}

.cart-product-discount__value {
  font-weight: $font-bold;
  font-size: $text-sm;
}
</style>
